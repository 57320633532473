.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #E6E6E6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

.image-container {
  display: grid;
  place-items: center;
  /* Andere Styles für das Raster (Grid) hier hinzufügen */
}

.scaled-image {
  max-width: 100%; /* Das Bild wird die Breite der übergeordneten Container nicht überschreiten */
  max-height: 100%; /* Das Bild wird die Höhe der übergeordneten Container nicht überschreiten */
  width: auto; /* Das Bild behält sein Seitenverhältnis bei, während es skaliert wird */
  height: auto; /* Das Bild behält sein Seitenverhältnis bei, während es skaliert wird */
  /* Andere Styles für das Bild hier hinzufügen */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-container {
  text-align: center; /* Zentriert den Inhalt des Containers */
  margin: auto;
  width: 50%; /* oder eine andere Breite, je nach Bedarf */
}

.text-left {
  text-align: left; /* Text links ausrichten */
  display: inline-block; /* Block-Element, das sich wie ein Inline-Element verhält */
  text-align: left; /* Text innerhalb des Blocks links ausrichten */
  max-width: 100%; /* Verhindert, dass der Block größer als sein Container wird */
}


.page-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.page-content {
  overflow: auto;
  /* overflow: hidden; */
  flex-grow: 1;
  align-items: flex-start !important;
  padding: 20px;
}